<template lang="pug">
  div
    v-dialog(v-model='confirmationDialogProduct' persistent max-width='290')
      v-card
        v-card-title.text-h5
          | Confirmar
        v-card-text ¿Estás seguro de eliminar este registro?
        v-card-actions
          v-spacer(style="text-align: right;")
            v-btn(color='red darken-1' text='' @click='confirmationDialogProduct = false')
              | Cancelar
            v-btn.success-btn(text='' @click='acceptConfirmationDelete')
              | Aceptar
    v-dialog(v-model='dialogWholesaler' persistent width='350')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | MINORISTA / MAYORISTA
        v-card-text
          v-row
            v-col(cols="6")
              v-checkbox(id="checkMin" :label="activeValView" style="margin: 0px;" v-model="checkMin" @click="clickCheckMin")
            v-col(cols="6")
              v-checkbox(id="checkMay" :label="activeValueWholesalerView" style="margin: 0px;" v-model="checkMay" @click="clickCheckMay")
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='cancelPriceDialog')
            | CANCELAR
          v-spacer
          v-btn.success-btn(text='' @click='setValSelected')
            | LISTO
    v-dialog(fullscreen="" v-model='dialodInvModCombo')
      v-card
        menu-combo-modal(@closeModal="closeInventoryModalCombo" :productToEdit='productToEditCombo' :forEditProduct='forEditProductCombo')
    v-dialog(fullscreen="" v-model='dialodInvMod')
      v-card
        inventoryDataModal(@closeModal="closeInventoryModal" :productToEdit='productToEdit' :forEditProduct='forEditProduct')
    v-dialog(v-model='dialogAddProduct' persistent max-width='500')
      v-card
        v-card-title Productos
        v-card-text
          v-row(style="margin-bottom: 10px;")
            v-col(cols="6" style="padding: 0px;")
              h3(style="padding-left: 22px;") IVA: {{valIvaTxt}}
            //- //v-col(cols="4" style="padding: 0px;")
            //-   v-checkbox(label="IVA CERO" style="margin: 0px;" v-model="product.porcentajecero")
            v-col(v-if="product.producto && product.producto.type.texto === 'BIEN'" cols="6" style="padding: 0px; text-align: right;")
              h3(style="padding-right: 25px; color: red;" v-if="product.producto.quantity - product.cantidad < 0") STOCK: {{product.producto.quantity - product.cantidad}}
              h3(style="padding-right: 25px;" v-if="product.producto.quantity - product.cantidad >= 0") STOCK: {{product.producto.quantity - product.cantidad}}
          v-form(ref='formAddProduct' v-model='validAddProduct' lazy-validation='' style="padding-top: 10px;")
            v-row
              v-col(cols='10' md='11' style='padding-top: 0; padding-bottom: 0;' v-if="!$store.state.profile.profileLoaded.productPrice")
                v-autocomplete(:rules='[rules.required]' @change="getProductValue(product, product.producto.activeValue, product.producto.tarifaiva.texto)" v-model='product.producto' :items='productsLst' return-object='return-object' item-text='completeData' label='Productos' autofocus)
              v-col(cols='10' md='11' style='padding-top: 0; padding-bottom: 0;' v-if="$store.state.profile.profileLoaded.productPrice")
                v-autocomplete(:rules='[rules.required]' @change="getProductValueWholesaler(product, product.producto.activeValue, product.producto.activeValueWholesaler, product.producto.tarifaiva.texto)" v-model='product.producto' :items='productsLst' return-object='return-object' item-text='completeData' label='Productos' autofocus)
              //-   <!--v-autocomplete(:rules='[rules.required]' @change="getProductValue(product, product.producto.activeValue, product.producto.tarifaiva.texto)" v-model='product.producto' :items='productsLst' return-object='return-object' item-text='completeData' label='Productos' autofocus)-->
              //- <!--v-col(cols='10' md='11' style='padding-top: 0; padding-bottom: 0;' v-if="$store.state.profile.profileLoaded.productPrice")-->
              //- <!--v-autocomplete(:rules='[rules.required]' @change="getProductValueWholesaler(product, product.producto.activeValue, product.producto.activeValueWholesaler, product.producto.tarifaiva.texto)" v-model='product.producto' :items='productsLst' return-object='return-object' item-text='completeData' label='Productos' autofocus)-->
              v-col.py-0.pl-0.ml-0(cols="2" md="1")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(v-on="on" icon text color="purple darken-3" @click="addNewProduct" style='background:#B18BE2;')
                      v-icon(style="font-size: 25px;") mdi-plus
                  span.tooltips Crear Producto
              v-col(cols='12' md="6" style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(type="text" @keypress="isNumber($event)" @keyup="calculateSubtotalKeyUp" :rules='[rules.required]' label="Cantidad" v-model="product.cantidad")
              v-col(cols='12' md="6")
                v-text-field.purple-input(type="text" @keypress="isNumber($event)" @keyup="calculateSubtotalKeyUp" :rules='[rules.required]' label="Valor Unitario SIN IVA" v-model="product.valorUnitario" style='padding-top: 0; padding-bottom: 0;')
              v-col(cols="12" md="6" style='padding-top: 0; padding-bottom: 0;')
                v-row(style='padding: 12px;')
                  v-switch(@change="calculateSubtotalKeyUp" color='primary' value='' v-model="banDescuento")
                  v-text-field.purple-input(type="text" @keypress="isNumber($event)" @keyup="calculateSubtotalKeyUp" :label="banDescuento ? 'DCTO. VALOR ($)' : 'DCTO. PORCENTAJE (%)'" v-model="dctoProduct")
              v-col(cols='12' md="6")
                v-text-field.purple-input(:rules='[rules.required]' label="Subtotal" v-model="product.total" type="text" @keypress="isNumber($event)" disabled)
              v-col(cols='12' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(@keyup="product.descripcion = product.descripcion.toUpperCase()" label="Observación" v-model="product.descripcion" type="text")
              v-col(cols="12" style='padding-top: 0; padding-bottom: 0;')
                v-alert(v-model="alertProductData" dismissible dense='' border='left' type='error')
                  | {{msgAlertProducts}}
            v-card-actions
              v-spacer(style="text-align: right;")
                v-btn(color='red darken-1' text='' @click='dialogAddProduct = false')
                  | Cancelar
                v-btn.success-btn(text='' @click="addProduct" :disabled="disableAdd")
                  | Agregar
    v-dialog(v-model='dialogAddCombo' persistent width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Combos
        v-card-text
          v-form(ref='formAddProductCombo' v-model='validAddProduct' lazy-validation='' style="padding-top: 10px;")
            v-row
              v-col(cols='12' md="6" style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(type="text" @keypress="isNumber($event)" @keyup="calculateSubtotal" :rules='[rules.required]' label="Cantidad" v-model="product.cantidad")
              v-col(cols='12' md="6" style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(:rules='[rules.required]' label="Subtotal" v-model="product.total" type="text" @keypress="isNumber($event)" disabled)
        v-divider
        v-card-actions
          v-spacer(style="text-align: right;")
            v-btn(color='red darken-1' text='' @click='dialogAddCombo = false')
              | Cancelar
            v-btn.success-btn(text='' @click="addProductCombo" :disabled="disableAdd")
              | Agregar
    v-snackbar(:timeout='10000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title
          h4(v-if="openTable.number === ''") Pedido de Mesa # {{(parseInt(tableSecuential) + 1).toString().padStart(9, '0')}}
          h4(v-if="openTable.number !== '' && openTable.secuential") Pedido de Mesa # {{(parseInt(openTable.secuential)).toString().padStart(9, '0')}}
          h4(v-if="openTable.number !== '' && !openTable.secuential") Pedido de Mesa
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModalTable')")
            v-icon.primary.rounded mdi-close
      v-card-text(style="padding-top: 0px;")
        v-row(style="margin-top: 20px;")
          v-form(ref='formOpenTable' v-model='validOpenTable' lazy-validation='' style="width: 100%; margin: 10px;")
            v-row
              div(style="width: 100%;")
                v-row(style='width: 100%; margin: 0;')
                  v-col(cols="12" md="5" style="padding-bottom: 0px;")
                    v-row(style='padding-top:0;')
                      v-col(cols="10" md="11" style="padding-top: 0px;")
                        v-text-field(v-model="searchCombo" @keyup="filterComboTxt" append-icon="mdi-search" label= "Buscar Combo | Código, Nombre, Descripción" single-line="" clearable style="margin-top: 0px; padding-top: 0px;")
                      v-col.py-0.pl-0.ml-0(cols="2" md="1" style="text-align: center;")
                        v-tooltip(bottom="")
                          template(v-slot:activator="{ on }")
                            v-btn(v-on="on" icon text color="purple darken-3" @click="addNewProductCombo" style='background:#B18BE2;')
                              v-icon(style="font-size: 25px;") mdi-plus
                          span.tooltips Crear Combo
                      v-col(cols="12" md="6" style="padding-top: 0px;")
                        v-text-field(@keyup="openTable.number = openTable.number.toUpperCase()" :rules='[rules.required]' v-model="openTable.number" label= "Número de Mesa" single-line="" clearable style="margin-top: 0px; padding-top: 0px;" type="number")
                      v-col(cols="12" md="6" style="padding-top: 0px;")
                        v-text-field(@keyup="openTable.waitress = openTable.waitress.toUpperCase()" v-model="openTable.waitress" label= "Mesera" single-line="" clearable style="margin-top: 0px; padding-top: 0px;" type="text")
                      v-col(cols='12' md="12")
                        v-textarea.purple-input(@keyup="openTable.observations = openTable.observations.toUpperCase()" label="Observaciones" v-model="openTable.observations")
                  v-col(cols="12" md="7" style="padding-bottom: 0px;")
                    v-card(class="view-combo-card" style="height: 270px; max-height: 270px !important;  min-height: 270px !important; overflow-y: scroll !important;")
                      v-card-text(style="padding: 0px;")
                        v-row(style="margin: 0px; padding: 0px;")
                          v-col(cols="3" md="2" v-for='(combo, index) in comboLstFilter' :key='index' style="margin: 0px; padding: 0px; padding-left: 5px; padding-right: 5px;").text-center
                            v-tooltip(bottom='')
                              template(v-slot:activator='{ on, attrs }')
                                v-btn(@click="addnewCombo(combo)" icon='' color='blue' v-on='on' style="background: transparent; margin: 10px; background-color: #1d1d1d; border-radius: 0px; width: 100%; height: 60px; margin-bottom: 0px;").mt-4
                                  v-icon(style="font-size: 40px;") mdi-food
                                  p(style="position: absolute; top: -17px; left: 5px; color: white; font-size: 20px; font-weight: bold;") {{combo.codArticle}}
                                p(style="font-size: 7px; text-align: center; width: 100%; font-weight: bold; margin-left: 10px; background-color: gray; color: white;") {{combo.name}}
                              span.tooltips {{combo.description}}
          v-row(style="margin-right: 20px;")
            v-col(cols="12" md="10" style="padding-bottom: 0px; padding-top: 0px;")
              v-row(style="margin-top: 10px;")
                v-col(cols="7" md="7" style="padding-top: 0px;")
                  h3(style="padding-left: 50px; padding-top: 20px;") Lista de Productos y Combos
                v-col(cols="4" sm='2' class='addButton' style="padding-top: 0px;").text-right
                  v-btn(color='green' @click="addNewElement").mt-4
                    | AGREGAR PRODUCTOS
                v-col(cols="12" v-if="viewTable")
                  v-data-table(style="height: 300px; min-height: 430px; overflow-y: scroll;" :headers="headers" :items="openTable.lstProductos" item-key="id" dense fixed-header :loading="cargando" loading-text="Cargando" :footer-props="{\'items-per-page-text':'Productos por página'\, 'items-per-page-options':[10, 20, 30, 50, 100]}" :items-per-page="100")
                    template(v-slot:item.changeCant="{ item }")
                      v-tooltip(bottom="")
                        template(v-slot:activator="{ on }")
                          v-btn(text="" icon="" color='blue' v-on="on" @click="addProductComboCant(true, item, item.producto.combo)")
                            v-icon(small) mdi-plus
                        span.tooltips Aumentar
                      v-tooltip(bottom="")
                        template(v-slot:activator="{ on }")
                          v-btn(text="" icon="" color='red' v-on="on" @click="addProductComboCant(false, item, item.producto.combo)")
                            v-icon(small) mdi-minus
                        span.tooltips Restar
                    template(v-slot:item.cantidadView="{ item }")
                      p(style="font-size: 12px; font-weight: bold;") {{item.cantidad}}
                    template(v-slot:item.descuentoView="{ item }")
                      p(style="font-size: 12px;") ${{item.descuento}}
                    template(v-slot:item.valorUnitarioView="{ item }")
                      p(style="font-size: 12px;") ${{item.valorUnitario}}
                    template(v-slot:item.totalView="{ item }")
                      p(style="font-size: 12px; font-weight: bold;") ${{item.total}}
                    template(v-slot:item.codView="{ item }")
                      p(style="font-size: 10px;") {{item.producto.codArticle}}
                    template(v-slot:item.nameView="{ item }")
                      p(style="font-size: 10px;") {{item.producto.name}}
                    template(v-slot:item.action="{ item }")
                      v-tooltip(bottom="")
                        template(v-slot:activator="{ on }")
                          v-btn(:disabled="item.producto.combo" text="" icon="" color='blue' v-on="on" @click="editElement(item)")
                            v-icon(small) mdi-lead-pencil
                        span.tooltips Editar
                      v-tooltip(bottom="")
                        template(v-slot:activator="{ on }")
                          v-btn(text="" icon="" color='red' v-on="on" @click="openDeleteItem(item)")
                            v-icon(small) mdi-trash-can-outline
                        span.tooltips Eliminar
                    v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                      | Sin resultados
                    v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                      | No se tiene datos
                    v-flex(xs12='', sm10='', offset-sm1='')
            v-col(cols="12" md="2" style="padding-bottom: 0px; padding-top: 0px;")
              v-row(style="padding-top: 30px;")
                v-col(cols="12" style="padding: 0px; text-align: right;")
                  label(style='font-size: 13px;') SUB TOTAL: ${{openTable.subtotal}}
                v-col(cols="12" style="padding: 0px; text-align: right;")
                  label(style='font-size: 13px;') IVA {{newTarifaIva}}%: ${{openTable.iva12}}
                hr(style="border-color: white; width: 100%; margin: 5px -5px 5px 50px;")
                v-col(cols="12" style="padding: 0px; text-align: right;")
                  label(style='font-size: 16px; font-weight: bold; width: 100px;') TOTAL: ${{openTable.total}}
              v-row
                v-col(cols="12" md="12" style="width: 100%;").text-center
                  v-btn(color='blue' @click="saveOpenTable" :loading="loadingSave" style="width: 100%;").mt-4
                    | GUARDAR MESA
</template>
<script>
  import * as functions from '../../../../functions.js'
  import moment from 'moment/moment'
  import * as vars from '../../../../vars.json'
  var accounting = require('accounting')
  export default {
    components: {
      MenuComboModal: () => import('@/views/dashboard/component/application/MenuComboModal'),
      InventoryDataModal: () => import('@/views/dashboard/component/application/InventoryDataModal'),
    },
    props: {
      openTableToEdit: {
        default: function () {
          return {
            secuential: 0,
            number: '',
            observations: '',
            lstProductos: [],
            subtotal: '0.00',
            subcero: '0.00',
            noobjiva: '0.00',
            excentoiva: '0.00',
            totdcto: '0.00',
            ice: '0.00',
            irbpnr: '0.00',
            iva12: '0.00',
            total: '0.00',
            createTime: new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
            createDate: moment(new Date()).format('YYYY-MM-DD'),
            waitress: '',
          }
        },
        type: Object,
      },
      tableSecuential: {
        type: Number,
        default: 0,
      },
    },
    data: () => ({
      newTarifaIva: '',
      selectedProduct: null,
      minDateForConsults: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      banSendOpenTable: '0',
      confirmationDialogProduct: false,
      dialogAddProduct: false,
      validAddProduct: true,
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      loadingSave: false,
      cargando: false,
      headers: [
        { text: '', value: 'changeCant', align: 'left', filterable: true, width: '120px' },
        { text: 'Cant.', value: 'cantidadView', align: 'left', filterable: true, width: '10px' },
        { text: 'Cod.', value: 'codView', align: 'left', filterable: true },
        { text: 'Nombre', value: 'nameView', align: 'left', filterable: true },
        { text: 'Dcto.', value: 'descuentoView', align: 'left', filterable: true },
        { text: 'Val. Unit.', value: 'valorUnitarioView', align: 'left', filterable: true },
        { text: 'Tot.', value: 'totalView', align: 'left', filterable: true },
        { text: 'Acciones', align: 'center', value: 'action', sortable: false, width: '120px' }, // No se cambia
      ],
      viewTable: true,
      forEditProduct: false,
      forEditProductCombo: false,
      dialodInvMod: false,
      dialodInvModCombo: false,
      productToEditCombo: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '02',
          texto: 'SERVICIO',
        },
        quantity: '1',
        activeValue: '',
        activeWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '2',
          texto: '12%',
          valor: '12',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
        inecCode: null,
        combo: true,
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      validOpenTable: true,
      openTable: {
        secuential: 0,
        number: '',
        observations: '',
        lstProductos: [],
        subtotal: '0.00',
        subcero: '0.00',
        noobjiva: '0.00',
        excentoiva: '0.00',
        totdcto: '0.00',
        ice: '0.00',
        irbpnr: '0.00',
        iva12: '0.00',
        total: '0.00',
        createTime: new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
        createDate: moment(new Date()).format('YYYY-MM-DD'),
        waitress: '',
      },
      searchCombo: '',
      comboLstFilter: [],
      dialogAddCombo: false,
      productToEdit: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '01',
          texto: 'BIEN',
        },
        quantity: '',
        activeValue: '',
        activeWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '2',
          texto: '12%',
          valor: '12',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
      },
      editProduct: false,
      product: {
        producto: null,
        cantidad: '',
        descripcion: '',
        valorUnitario: '',
        total: '',
        porcentajecero: '',
        descuento: '',
        ice: '',
        irbpnr: '',
      },
      disableAdd: false,
      valIvaTxt: '',
      msgAlertProducts: '',
      alertProductData: false,
      checkMay: true,
      checkMin: true,
      dctoProduct: '',
      banDescuento: true,
      activeValView: '',
      activeValueWholesalerView: '',
      txtIvaView: '',
      dialogWholesaler: false,
    }),
    computed: {
      companyDataLoad () {
        return this.$store.state.facturacion.companydata
      },
      comboLst () {
        return this.$store.state.product.lstCombos
      },
      productsLst () {
        return this.$store.state.product.lstProductsActive
      },
    },
    watch: {
      comboLst () {
        this.comboLstFilter = this.comboLst
      },
      openTableToEdit () {
        this.openTable = this.openTableToEdit
      },
    },
    mounted () {
      this.newTarifaIva = vars.validIva
      this.comboLstFilter = this.$store.state.product.lstCombos
      this.openTable = this.openTableToEdit
    },
    methods: {
      deleteItem (item) {
        let cont = 0
        let ban = true
        while (cont < this.openTable.lstProductos.length && ban) {
          if (this.openTable.lstProductos[cont].producto.codArticle.toString() === item.producto.codArticle.toString()) {
            this.openTable.lstProductos.splice(cont, 1)
            ban = false
          }
          cont++
        }
        this.calculateSubtotal()
        this.calculateVals()
      },
      acceptConfirmationDelete () {
        this.deleteItem(this.deleteItemSend)
        this.confirmationDialogProduct = false
        this.editProduct = false
      },
      addProductComboCant (ban, item, banCombo) {
        this.product = item
        if (ban) {
          this.product.cantidad = parseFloat(this.product.cantidad) + 1
        } else {
          this.product.cantidad = parseFloat(this.product.cantidad) - 1
        }
        this.editProduct = true
        // this.dialogAddProduct = true
        this.banDescuento = true
        this.product = item
        this.dctoProduct = this.product.descuento
        this.alertProductData = false

        if (banCombo) {
          this.viewTable = true
          if (parseFloat(this.product.cantidad) <= 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'La cantidad no puede ser menor de 1.'
            this.product.cantidad = 1
            this.calculateSubtotal()
            return
          } else {
            this.calculateSubtotal()
          }
          let cont = 0
          let ban = true
          if (!this.editProduct) {
            while (cont < this.openTable.lstProductos.length && ban) {
              if (this.openTable.lstProductos[cont].producto.codArticle.toString() === this.product.producto.codArticle.toString()) {
                ban = false
              }
              cont++
            }
            if (ban) {
              this.product.producto.description = this.product.producto.description.replace(/(\r\n|\n|\r)/gm, ' ')
              if (this.product.producto.description.length > 300) {
                this.product.producto.description = this.product.producto.description.substring(0, 299)
              }
              this.openTable.lstProductos.push(this.product)
            } else {
              if (!ban) {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Producto ya agregado dentro de la mesa puede modificar su cantidad.',
                }
                this.dialogAddCombo = false
                return
              }
            }
          }
          this.dialogAddCombo = false
          this.viewTable = true
          this.calculateVals()
          this.product = {
            producto: null,
            cantidad: '',
            descripcion: '',
            valorUnitario: '',
            total: '',
            porcentajecero: '',
            retencionir: '',
            retencioniva: '',
            retencionirval: '0',
            retencionivaval: '0',
            cuentagasto: null,
            descuento: '',
          }
          this.dctoProduct = ''
          this.banDescuento = true
        } else {
          this.viewTable = true
          if (parseFloat(this.product.cantidad) <= 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'La cantidad no puede ser menor de 1.'
            this.product.cantidad = 1
            this.calculateSubtotalKeyUp()
            return
          } else {
            this.calculateSubtotalKeyUp()
          }
          let cont = 0
          let ban = true
          if (!this.editProduct) {
            while (cont < this.openTable.lstProductos.length && ban) {
              if (this.openTable.lstProductos[cont].producto.codArticle.toString() === this.product.producto.codArticle.toString()) {
                ban = false
              }
              cont++
            }
            if (ban) {
              this.product.producto.description = this.product.producto.description.replace(/(\r\n|\n|\r)/gm, ' ')
              if (this.product.producto.description.length > 300) {
                this.product.producto.description = this.product.producto.description.substring(0, 299)
              }
              this.openTable.lstProductos.push(this.product)
            } else {
              if (!ban) {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Producto ya agregado dentro de la mesa puede modificar su cantidad.',
                }
                this.dialogAddProduct = false
                return
              }
            }
          }
          this.dialogAddProduct = false
          this.viewTable = true
          this.calculateVals()
          this.product = {
            producto: null,
            cantidad: '',
            descripcion: '',
            valorUnitario: '',
            total: '',
            porcentajecero: '',
            retencionir: '',
            retencioniva: '',
            retencionirval: '0',
            retencionivaval: '0',
            cuentagasto: null,
            descuento: '',
          }
          this.dctoProduct = ''
          this.banDescuento = true
        }
      },
      clickCheckMin () {
        if (this.checkMin) {
          this.checkMay = false
        } else {
          this.checkMay = true
        }
      },
      clickCheckMay () {
        if (this.checkMay) {
          this.checkMin = false
        } else {
          this.checkMin = true
        }
      },
      cancelPriceDialog () {
        this.dialogWholesaler = false
        this.dialogAddProduct = false
      },
      setValSelected () {
        if (this.checkMin) {
          this.product.valorUnitario = parseFloat(this.activeValView.toString().replace(',', '.'))
          this.valIvaTxt = this.txtIvaView
          this.calculateSubtotal()
        } else {
          this.product.valorUnitario = parseFloat(this.activeValueWholesalerView.toString().replace(',', '.'))
          this.valIvaTxt = this.txtIvaView
          this.calculateSubtotal()
        }
        this.dialogWholesaler = false
      },
      getProductValueWholesaler (product, activeVal, activeValueWholesaler, txtIva) {
        if (product.producto.activeValueWholesaler) {
          if (product.producto.activeValueWholesaler.toString().trim() !== '') {
            if (parseFloat(product.producto.activeValueWholesaler) > 0) {
              this.activeValView = activeVal
              this.activeValueWholesalerView = activeValueWholesaler
              this.txtIvaView = txtIva
              this.dialogWholesaler = true
              this.checkMin = true
              this.checkMay = false
            } else {
              product.valorUnitario = parseFloat(activeVal.toString().replace(',', '.'))
              this.valIvaTxt = txtIva
              this.calculateSubtotal()
            }
          } else {
            product.valorUnitario = parseFloat(activeVal.toString().replace(',', '.'))
            this.valIvaTxt = txtIva
            this.calculateSubtotal()
          }
        } else {
          product.valorUnitario = parseFloat(activeVal.toString().replace(',', '.'))
          this.valIvaTxt = txtIva
          this.calculateSubtotal()
        }
      },
      async closeInventoryModal () {
        this.dialodInvMod = false
        if (this.selectedProduct) {
          this.getProductValue(this.selectedProduct, this.selectedProduct.producto.activeValue, this.selectedProduct.producto.tarifaiva.texto)
          this.selectedProduct = null
        }
      },
      calculateSubtotalKeyUp () {
        if (this.product.producto) {
          if (this.product.valorUnitario === null || this.product.valorUnitario === '') {
            this.disableAdd = true
            this.msgAlertProducts = 'Debe agregar el precio unitario del producto.'
            this.alertProductData = true
            return
          }
          const valRest = parseFloat(this.product.producto.quantity) - parseFloat(this.product.cantidad)
          if (valRest < 0 && this.product.producto.type.texto === 'BIEN' && !this.profileLoaded.negativeInventory) {
            this.disableAdd = true
            this.msgAlertProducts = 'La cantidad excede el número de productos en stock.'
            this.alertProductData = true
            return
          } else {
            this.disableAdd = false
          }
          this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
          if (this.dctoProduct && this.dctoProduct.toString() !== '') {
            if (this.banDescuento) {
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(this.dctoProduct)), 3)
              this.product.descuento = this.dctoProduct
            } else {
              const valDcto = accounting.toFixed(((parseFloat(this.product.total) * parseFloat(this.dctoProduct)) / 100), 3)
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(valDcto)), 3)
              this.product.descuento = valDcto
            }
          }
          if (parseFloat(this.product.total) < 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'El descuento no puede ser mayor que el valor total a pagar por produto.'
            this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
            this.banDescuento = true
            this.dctoProduct = ''
            this.product.descuento = this.dctoProduct
          } else {
            this.alertProductData = false
          }
        }
      },
      addProduct () {
        this.viewTable = true
        if (this.$refs.formAddProduct.validate()) {
          if (parseFloat(this.product.cantidad) <= 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'La cantidad no puede ser menor de 1.'
            this.product.cantidad = 1
            this.calculateSubtotalKeyUp()
            return
          }
          let cont = 0
          let ban = true
          if (!this.editProduct) {
            while (cont < this.openTable.lstProductos.length && ban) {
              if (this.openTable.lstProductos[cont].producto.codArticle.toString() === this.product.producto.codArticle.toString()) {
                ban = false
              }
              cont++
            }
            if (ban) {
              this.product.producto.description = this.product.producto.description.replace(/(\r\n|\n|\r)/gm, ' ')
              if (this.product.producto.description.length > 300) {
                this.product.producto.description = this.product.producto.description.substring(0, 299)
              }
              this.openTable.lstProductos.push(this.product)
            } else {
              if (!ban) {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Producto ya agregado dentro de la mesa puede modificar su cantidad.',
                }
                this.dialogAddProduct = false
                return
              }
            }
          }
          this.dialogAddProduct = false
          this.viewTable = true
          this.calculateVals()
          this.product = {
            producto: null,
            cantidad: '',
            descripcion: '',
            valorUnitario: '',
            total: '',
            porcentajecero: '',
            retencionir: '',
            retencioniva: '',
            retencionirval: '0',
            retencionivaval: '0',
            cuentagasto: null,
            descuento: '',
          }
          this.dctoProduct = ''
          this.banDescuento = true
        }
      },
      async saveOpenTable () {
        if (this.banSendOpenTable === '0') {
          this.banSendOpenTable = '1'
          setTimeout(() => {
            this.banSendOpenTable = '0'
          }, 1000)
          if (this.$refs.formOpenTable.validate()) {
            if (this.openTable.lstProductos.length === 0) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Para continuar primero debes agregar los productos',
              }
              return
            }
            this.loadingSave = true
            let routeState = ''
            // para nuevos pedidos de mesa
            if (!this.openTable.id) {
              routeState = 'table/setOpenTable'
              this.openTable.secuential = this.tableSecuential ? (this.tableSecuential + 1) : 1
              await this.$store.dispatch('table/setActualSecuential', this.openTable.secuential)
            } else { // para edicion de pedidos existentes
              routeState = 'table/updateOpenTable'
            }
            this.openTable.createTime = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
            this.openTable.createDate = moment(new Date()).format('YYYY-MM-DD')
            this.$store.dispatch(routeState, this.openTable).then(res => {
              this.loadingSave = false
              if (res) {
                this.loadingSave = false
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Mesa agregada correctamente',
                }
                if (this.$store.state.profile.profileLoaded.comanda) {
                  const myWindow = window.open('', 'print-sale', 'fullscreen="yes"')
                  this.printMesa(myWindow)
                }
                const data = {
                  msg: 'SUCCESS',
                  obj: this.openTable,
                }
                this.$emit('closeModalTable', data)
              } else {
                this.loadingSave = false
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al momento de agregar la mesa.',
                }
              }
            }, reject => {
              this.loadingSave = false
              this.snackbar = {
                show: true,
                color: 'orange',
                text: 'Mesa generada con inconvenientes.',
              }
              setTimeout(() => {
                const data = {
                  msg: 'ERROR',
                  obj: this.openTable,
                }
                this.$emit('closeModalTable', data)
              }, 3000)
            })
          }
        }
      },
      printMesa (myWindow) {
        if (this.$store.state.profile.profileLoaded.comanda) {
          this.openTable.companyData = this.companyDataLoad
          functions.printDataOpenTable(this.openTable, this.$store.state.profile.profileLoaded, myWindow)
        }
      },
      openDeleteItem (item) {
        this.deleteItemSend = item
        this.confirmationDialogProduct = true
      },
      editElement (item) {
        this.editProduct = true
        this.dialogAddProduct = true
        this.banDescuento = true
        this.product = item
        this.dctoProduct = this.product.descuento
        this.alertProductData = false
      },
      addProductCombo () {
        this.viewTable = true
        if (this.$refs.formAddProductCombo.validate()) {
          if (parseFloat(this.product.cantidad) <= 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'La cantidad no puede ser menor de 1.'
            this.product.cantidad = 1
            this.calculateSubtotal()
            return
          }
          let cont = 0
          let ban = true
          if (!this.editProduct) {
            while (cont < this.openTable.lstProductos.length && ban) {
              if (this.openTable.lstProductos[cont].producto.codArticle.toString() === this.product.producto.codArticle.toString()) {
                ban = false
              }
              cont++
            }
            if (ban) {
              this.product.producto.description = this.product.producto.description.replace(/(\r\n|\n|\r)/gm, ' ')
              if (this.product.producto.description.length > 300) {
                this.product.producto.description = this.product.producto.description.substring(0, 299)
              }
              this.openTable.lstProductos.push(this.product)
            } else {
              if (!ban) {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Producto ya agregado dentro de la mesa puede modificar su cantidad.',
                }
                this.dialogAddCombo = false
                return
              }
            }
          }
          this.dialogAddCombo = false
          this.viewTable = true
          this.calculateVals()
          this.product = {
            producto: null,
            cantidad: '',
            descripcion: '',
            valorUnitario: '',
            total: '',
            porcentajecero: '',
            retencionir: '',
            retencioniva: '',
            retencionirval: '0',
            retencionivaval: '0',
            cuentagasto: null,
            descuento: '',
          }
          this.dctoProduct = ''
          this.banDescuento = true
        }
      },
      calculateVals () {
        let sub = 0
        let iva = 0
        let dcto = 0
        let subcero = 0
        let noobjiva = 0
        let exentoiva = 0
        let valDcto = 0
        for (let i = 0; i < this.openTable.lstProductos.length; i++) {
          const valTot = parseFloat(this.openTable.lstProductos[i].cantidad * this.openTable.lstProductos[i].valorUnitario)
          if (this.openTable.lstProductos[i].descuento && this.openTable.lstProductos[i].descuento.toString() !== '') {
            valDcto = parseFloat(this.openTable.lstProductos[i].descuento)
            sub = sub + parseFloat(valTot) - parseFloat(valDcto)
            iva = iva + ((parseFloat(valTot) - parseFloat(valDcto)) * ((parseFloat(this.openTable.lstProductos[i].producto.tarifaiva.valor)) / 100))
            dcto = dcto + valDcto
            if (this.openTable.lstProductos[i].producto.tarifaiva.codigo.toString() === '0') {
              subcero = subcero + parseFloat(valTot) - parseFloat(valDcto)
            }
            if (this.openTable.lstProductos[i].producto.tarifaiva.codigo.toString() === '6') {
              noobjiva = noobjiva + parseFloat(valTot) - parseFloat(valDcto)
            }
            if (this.openTable.lstProductos[i].producto.tarifaiva.codigo.toString() === '7') {
              exentoiva = exentoiva + parseFloat(valTot) - parseFloat(valDcto)
            }
          } else {
            sub = sub + parseFloat(valTot)
            iva = iva + ((parseFloat(valTot)) * ((parseFloat(this.openTable.lstProductos[i].producto.tarifaiva.valor)) / 100))
            if (this.openTable.lstProductos[i].producto.tarifaiva.codigo.toString() === '0') {
              subcero = subcero + parseFloat(valTot)
            }
            if (this.openTable.lstProductos[i].producto.tarifaiva.codigo.toString() === '6') {
              noobjiva = noobjiva + parseFloat(valTot)
            }
            if (this.openTable.lstProductos[i].producto.tarifaiva.codigo.toString() === '7') {
              exentoiva = exentoiva + parseFloat(valTot)
            }
          }
        }
        const tot = parseFloat(sub) + parseFloat(iva)
        const saldo = parseFloat(accounting.toFixed(tot, 2))
        this.openTable.subtotal = accounting.toFixed(sub, 2)
        this.openTable.subcero = accounting.toFixed(subcero, 2)
        this.openTable.noobjiva = accounting.toFixed(noobjiva, 2)
        this.openTable.excentoiva = accounting.toFixed(exentoiva, 2)
        this.openTable.totdcto = accounting.toFixed(dcto, 2)
        this.openTable.ice = '0.00'
        this.openTable.irbpnr = '0.00'
        this.openTable.iva12 = accounting.toFixed(iva, 2)
        this.openTable.total = accounting.toFixed(tot, 2)
        this.openTable.saldo = accounting.toFixed(saldo, 2)
      },
      addNewElement () {
        this.product = {
          producto: null,
          cantidad: '',
          descripcion: '',
          valorUnitario: '',
          total: '',
          porcentajecero: '',
          retencionir: '',
          retencioniva: '',
          retencionirval: '0',
          retencionivaval: '0',
          cuentagasto: null,
          descuento: '',
        }
        this.editProduct = false
        this.dialogAddProduct = true
        this.product.cantidad = 1
        this.banDescuento = true
        this.dctoProduct = ''
        this.alertProductData = false
        this.disableAdd = false
      },
      async closeInventoryModalCombo () {
        this.dialodInvModCombo = false
      },
      filterComboTxt () {
        this.comboLstFilter = this.comboLst.filter(item => {
          return item.codArticle.toUpperCase().includes(this.searchCombo.toUpperCase()) || item.name.toUpperCase().includes(this.searchCombo.toUpperCase()) || item.description.toUpperCase().includes(this.searchCombo.toUpperCase())
        })
      },
      getProductValue (product, activeVal, txtIva) {
        this.selectedProduct = product
        if (!product.producto.validChangeIva) {
          this.forEditProduct = true
          this.dialodInvMod = true
          this.productToEdit = product.producto
          return
        }
        product.valorUnitario = parseFloat(activeVal.toString().replace(',', '.'))
        this.valIvaTxt = txtIva
        this.calculateSubtotal()
      },
      calculateSubtotal () {
        if (this.product.producto) {
          if (this.product.valorUnitario === null || this.product.valorUnitario === '') {
            this.disableAdd = true
            this.msgAlertProducts = 'Debe agregar el precio unitario del producto.'
            this.alertProductData = true
            return
          }
          const valRest = parseFloat(this.product.producto.quantity) - parseFloat(this.product.cantidad)
          if (valRest < 0 && this.product.producto.type.texto === 'BIEN' && !this.profileLoaded.negativeInventory) {
            this.disableAdd = true
            this.msgAlertProducts = 'La cantidad excede el número de productos en stock.'
            this.alertProductData = true
            return
          } else {
            this.disableAdd = false
          }
          if (this.product.producto.tarifaiva.valor.toString() !== '0') {
            const valMult = 1 + (parseFloat(this.product.producto.tarifaiva.valor) / 100)
            // if (this.product.producto.activeWValue) {
            //   this.product.valorUnitario = (parseFloat(this.product.producto.activeWValue) / valMult).toFixed(3)
            // } else {
            //   this.product.valorUnitario = parseFloat(this.product.producto.activeValue)
            // }
            if (this.$store.state.profile.profileLoaded.productPrice) {
              if (this.checkMay) {
                if (this.product.producto.activeWValueWholesaler) {
                  this.product.valorUnitario = (parseFloat(this.product.producto.activeWValueWholesaler) / valMult).toFixed(3)
                } else {
                  // this.product.valorUnitario = (parseFloat(this.product.producto.activeWValue) / valMult).toFixed(3)
                  if (this.product.producto.activeWValue) {
                    this.product.valorUnitario = (parseFloat(this.product.producto.activeWValue) / valMult).toFixed(3)
                  } else {
                    this.product.valorUnitario = parseFloat(this.product.producto.activeValue)
                  }
                }
              } else {
                // this.product.valorUnitario = (parseFloat(this.product.producto.activeWValue) / valMult).toFixed(3)
                if (this.product.producto.activeWValue) {
                  this.product.valorUnitario = (parseFloat(this.product.producto.activeWValue) / valMult).toFixed(3)
                } else {
                  this.product.valorUnitario = parseFloat(this.product.producto.activeValue)
                }
              }
            } else {
              // this.product.valorUnitario = (parseFloat(this.product.producto.activeWValue) / valMult).toFixed(3)
              if (this.product.producto.activeWValue) {
                this.product.valorUnitario = (parseFloat(this.product.producto.activeWValue) / valMult).toFixed(3)
              } else {
                this.product.valorUnitario = parseFloat(this.product.producto.activeValue)
              }
            }
          }
          this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
          if (this.dctoProduct && this.dctoProduct.toString() !== '') {
            if (this.banDescuento) {
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(this.dctoProduct)), 3)
              this.product.descuento = this.dctoProduct
            } else {
              const valDcto = accounting.toFixed(((parseFloat(this.product.total) * parseFloat(this.dctoProduct)) / 100), 3)
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(valDcto)), 3)
              this.product.descuento = valDcto
            }
          }
          if (parseFloat(this.product.total) < 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'El descuento no puede ser mayor que el valor total a pagar por produto.'
            this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
            this.banDescuento = true
            this.dctoProduct = ''
            this.product.descuento = this.dctoProduct
          } else {
            this.alertProductData = false
          }
        }
      },
      addnewCombo (combo) {
        this.dialogAddCombo = true
        this.productToEdit = combo
        this.editProduct = false
        this.product = {
          producto: this.productToEdit,
          cantidad: '1',
          descripcion: '',
          valorUnitario: this.productToEdit.activeValue,
          total: this.productToEdit.activeValue,
          porcentajecero: '',
          descuento: '',
          ice: '',
          irbpnr: '',
        }
        this.getProductValue(this.product, this.product.producto.activeValue, this.product.producto.tarifaiva.texto)
      },
      addNewProduct () {
        this.forEditProduct = false
        this.dialodInvMod = true
        this.productToEdit = {
          codArticle: '',
          barcode: '',
          name: '',
          description: '',
          type: {
            codigo: '01',
            texto: 'BIEN',
          },
          quantity: '',
          activeValue: '',
          activeWValue: '',
          productKey: '',
          completeData: '',
          tarifaiva: {
            codigo: '2',
            texto: '12%',
            valor: '12',
          },
          irbpnr: '',
          tarifaice: '',
          totalValue: '',
        }
      },
      addNewProductCombo () {
        this.forEditProductCombo = false
        this.dialodInvModCombo = true
        this.productToEditCombo = {
          codArticle: '',
          barcode: '',
          name: '',
          description: '',
          type: {
            codigo: '02',
            texto: 'SERVICIO',
          },
          quantity: '1',
          activeValue: '',
          activeWValue: '',
          productKey: '',
          completeData: '',
          tarifaiva: {
            codigo: '2',
            texto: '12%',
            valor: '12',
          },
          irbpnr: '',
          tarifaice: '',
          totalValue: '',
          inecCode: null,
          combo: true,
        }
      },
    },
  }
</script>
